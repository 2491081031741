import logo from './japamala.png';
import './style.css';
import './botoes/style.css'
import {Component} from 'react';

class App extends Component{
  state ={
    contagem: 0
  };

  contar(){
    const{contagem} = this.state;

    //navigator.vibrate(200);
    this.setState({contagem: contagem +1});

    if(this.state.contagem === 107){
      alert('Finalizado!!!')
      /*setTimeout(()=>{
        navigator.vibrate(2000);
        setTimeout(()=>{
          alert('Finalizado!!!')
        }, 300)
      }, 100)*/
    }
  }
  resetar(){
    this.setState({contagem: 0});
  }

  render(){
    const {contagem} = this.state;
    return (
      <div className="box">
        <div className="main">
          <img src={logo} className='App-logo' alt="logo" />
          <span className='japamala'></span>
          <p>{contagem}</p>
          
          <button className='botao refresh' onClick={()=>this.resetar()}>&#x21bb;</button>
          <button className="botao contar" onClick={()=>this.contar()}>Contar</button>
        </div>
      </div>
    );
  }
}



export default App;
